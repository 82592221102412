/* eslint-disable */
import apiClient, { API_TYPES } from '@services/hafh/api';
import { Area, SearchProperty } from '@utils/types';

const api = apiClient(API_TYPES.API);

type InitialState = { results: (Area | SearchProperty)[] };
type Action = { type: string; payload: { results: (Area | SearchProperty)[] } };

const initialState = {
  results: [],
};

// action types
export const SET_RESULT = 'hafh/search/SET_RESULT';

// reducers
const search = (state: InitialState = initialState, action: Action) => {
  switch (action.type) {
    case SET_RESULT:
      return {
        ...state,
        results: action.payload.results || [],
      };
    default:
      return state;
  }
};

// action creators
export const updateSearchResult = (result = {}) => ({
  type: SET_RESULT,
  payload: result,
});

// actions
export const autoComplete = (q, locale) => async (dispatch) => {
  const res = await api.get('search', { q }, locale);

  if (res) {
    dispatch(updateSearchResult(res));
  }

  return res;
};

export const getActivePropertiesAndAreas =
  (propertyIds, areaIds, locale) => async () => {
    const res = await api.get(
      'search/history',
      { property_ids: propertyIds, area_ids: areaIds },
      locale
    );

    return res;
  };

export default search;
