/* eslint-disable */
import apiClient, { API_TYPES } from '@services/hafh/api';

const api = apiClient(API_TYPES.API);

const initialState = [];

// action types
export const UPDATE_BANNERS = 'hafh/banners/UPDATE_BANNERS';

// reducers
const Banners = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_BANNERS:
      return action.payload;
    default:
      return state;
  }
};

export const getBanners =
  (locale: string, authUser = null) =>
  async (dispatch) => {
    const banners = await api.get('banners', {}, locale, authUser);

    if (banners) {
      dispatch(updateBanners(banners));
      return banners;
    }
  };

// actions creators
export const updateBanners = (banners = []) => ({
  type: UPDATE_BANNERS,
  payload: banners,
});

export default Banners;
